import config from './base'

// localhost config
const localhostConfig = {
  // RELAY_SERVER_URL: 'http://localhost:8080/api/v1/utils/relay',
  // RELAY_SERVER_URL: 'https://dev.api.veenreader.com/relay',
  RELAY_SERVER_URL: 'https://shark-backend.herokuapp.com/api/v1/utils/relay',
  LEGATO_SERVER_URL: 'http://192.168.29.201:8088',
  reduxLoggerEnabled: false, //process.env.LOCALHOST === 'yes',
}

let finalConfig = config
config.isLocalHost && (finalConfig = { ...config, ...localhostConfig })

export default finalConfig
