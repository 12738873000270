import React from 'react'
import styled from 'styled-components'
import { useMessage } from 'APP/language'
import Tags from './Tags'
// import AdSense from 'react-adsense';
import AdSense from './GoogleAd'

const formatTime = (timeString, months) => {
  let month = '-'
  let year = '-'
  let date = '-'
  let time = '-'

  try {
    let dateObj = new Date(timeString)
    if (isNaN(dateObj.getDate())) {
      throw Error('Invalid date')
    }
    month = months[dateObj.getMonth()]
    date = dateObj.getDate()
    year = dateObj.getFullYear()
    time = dateObj.toLocaleTimeString().split(':')
    let ampm = 'am'
    if (time[0] > 12) {
      time[0] = time[0] - 12
      ampm = 'pm'
    }
    time = `${time.slice(0, 2).join(':')}${ampm}`
  } catch (error) {}
  return { month, year, date, time }
}

const ArticleView = ({ story, parsedArticle, storyMeta, tags, systemTags, userTags, removeTagfromStory, addTagToStory, tagsWithStoryIds }) => {
  const [byAuthorLabel, months] = useMessage('BY_AUTHOR', 'MONTHS')
  const { time, month, year, date } = formatTime(story.published, months)
  const content = parsedArticle || story.content || story.summary || { content: 'No details' }
  const handleTagClick = ({ tag, isSelected }) => {
    isSelected && removeTagfromStory({ tag, story })
    !isSelected && addTagToStory({ tag, story })
  }
  return (
    <Container>
      <StoryTitle>{story.title}</StoryTitle>
      <StoryMeta>
        <div>
          {byAuthorLabel} {story.author || 'Unknown'}
        </div>
        <div>{'//'}</div>
        <div>
          {month} {date}, {year} {time}
        </div>
      </StoryMeta>
      <Tags tags={userTags} tagsWithStoryIds={tagsWithStoryIds} story={story} handleTagClick={handleTagClick} />
      <StoryContent dangerouslySetInnerHTML={{ __html: content.content }}></StoryContent>
      <AdSense.Google key={story.id} client="ca-pub-1136075622746933" slot="3326723188" style={{ width: 720, height: 300, float: 'right' }} format="fluid" />
    </Container>
  )
}

const Container = styled.div`
  max-width: 800px;
  margin: 0px auto;
  color: ${(p) => p.theme.storyDetailsTextColor};
`

const StoryTitle = styled.h1`
  padding: 0px 12px;
  color: ${(p) => p.theme.storyDetailsTitleTextColor};
`

const StoryMeta = styled.div`
  padding: 0px 16px;
  display: flex;

  padding-bottom: 8px;
  color: ${(p) => p.theme.subduedText};
  font-size: 14px;
  font-weight: 600;
  margin-top: -8px;

  div:nth-child(2) {
    margin: 0px 8px;
  }
`

const StoryContent = styled.div`
  & img {
    max-width: 100%;
    height: unset !important;
  }
  & video {
    max-width: 100%;
    height: unset !important;
  }
  & iframe {
    max-width: 99%;
    border: 1px solid ${(p) => p.theme.frameBorderColor};
  }
  & object {
    max-width: 99%;
  }
  & em {
    color: ${(p) => p.theme.subduedText};
  }
  padding: 0px 32px;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  & a {
    color: ${(p) => p.theme.linkTextColor};
    text-decoration: underline;
  }
`

export default ArticleView
